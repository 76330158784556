<template>
    <section>
        <SearchBar 
            :placeholder="'Search class'" 
            :value.sync="search" 
            @clear="() => { search = '', page = 1, getEnrollees() }" 
            @search="() => { page = 1, getEnrollees()}" 
            class="mb-5" />
        <section class="text-right">
            <FormLabel :label="`${totalCount} result/s`"/>
        </section>
        <v-card>
            <v-data-table
                :loading="loading"
                :headers="courseEnrollment"
                :items="enrollees"
                class="poppins"
                :footer-props="{
                    'items-per-page-options': itemsPerPageOptions
                }"
                :page="page"
                :server-items-length="totalCount"
                @pagination="(e) => {
                    page = e.page
                    paginate = String(e.itemsPerPage),
                    getEnrollees()
                }">
                <template v-slot:header.pending_invoices="{ header }" >
                    <span dense class="secondary-2--text px-2 border" style="background-color: #BDBDBD33">{{ header.text  }}</span>
                </template>
                <template v-slot:header.success_invoices="{ header }" >
                    <span dense class="success--text px-2 border" style="background-color: #7BC14533">{{ header.text  }}</span>
                </template>
                <template v-slot:header.fail_invoices="{ header }" >
                    <span dense class="danger--text px-2 border" style="background-color: #F8835E33">{{ header.text  }}</span>
                </template>
                <template v-slot:item.action="{ item }" >
                    <v-btn dense icon color="primary" @click="$router.push({ name: 'Instructor User Enrollment', params: { course_uuid: item.uuid }})">
                        <v-icon small>
                            mdi-eye-outline
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <!-- <FormPagination 
                :pageCount="pageCount" 
                :page="page"
                :paginate="paginate"
                @page="(e) => { page = e, getEnrollees() }" 
                @paginate="(e) => { paginate = e, page = 1, getEnrollees() }"/> -->
        </v-card>
    </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { courseEnrollment } from '@/constants/tblheaders/enrollment';

export default {
    data: () => ({
        search: '',
        loading: false,
        courseEnrollment,
        pageCount: 1,
        page: 1,
        paginate: '10',
        totalCount: 0,
        itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
    }),
    methods: {
        ...mapActions('instructor', ['getEnrolleesAction']),
        ...mapMutations(['alertMutation']),

        getEnrollees() {
            if(!this.loading) {
                this.loading = true
                this.getEnrolleesAction({page: this.page, paginate: Number(this.paginate), search: this.search}).then(res => {
                    this.loading = false
                    this.pageCount = res.last_page
                    this.totalCount = res.total
                }).catch(() => {
                    this.loading = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong',
                        type: "error"
                    })
                })
            }
        }
    },
    computed: {
        ...mapState('instructor', {
            enrollees: (state) => state.enrollees
        }),

        itemsPerPage(){
            return Number(this.paginate)
        }
    },
    mounted() {
        this.getEnrollees()
    },
}
</script>
